import axios from 'axios';
import '@resources/css/tutorial.css';
import OTPModel from '../Modals/OTPModal';
import { useSelector } from 'react-redux';
import Tutorials from '../Tutorials/Tutorials';
import AlertMessage from '../Common/AlertMessage';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PerformInspection from '../Dashboard/PerformInspection';
import useOTPVerification from '../AI/CustomHooks/useOTPVerification';

const Home = () => {
    const params = useParams();
    const history = useHistory();
    const [message, setMessage] = useState('');
    const { orderNumber } = useSelector(state => state.order);
    const [guidelineChecked, setGuidelineChecked] = useState(false); 

    const {
        maskedNumber,
        otpPopUp,
        sendOTP,
        verifyOTP,
        closeModal,
    } = useOTPVerification();

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/inspection-ai/guidelines`)
            .then(res => {
                if (res.data.StatusCode == 1) {
                    setGuidelineChecked(res.data.Result == 1);
                }
            });
    }, [params.orderId]);
    

    const submitHandler = async (e) => {
        if(e) {
            e.preventDefault();
        }

        if (!guidelineChecked) {
            setMessage('Please check the guideline first');
            return ;
        }

        if (orderNumber) {
            history.push('start');
            return;
        }
            await sendOTP();
    };


    const onClose = () => {
        setMessage('');
    };
    return (
        <>
            {otpPopUp ? (
                <OTPModel
                    number={maskedNumber}
                    handleClose={closeModal}
                    handleVerify={verifyOTP}
                    submitHandler={submitHandler}
                />
            ) : (
                <div className="home p-4">
                    <AlertMessage message={message} onClose={onClose} />
                    <Tutorials />
                    <div 
                        className={`perform-inspection-container ${guidelineChecked ? 'enabled' : 'disabled'}`}
                    >
                        <PerformInspection submit={submitHandler} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Home;
