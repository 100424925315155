
const Banner = () => {
    const bannerComponents =[
        "Independent",
        "Objective",
        "Unbiased",
        "Scientific"
    ];
    
  return (
    <div>
        <div className="hero-banner">
            <div className="banner-content">
                <h1 className="highlight-txt">Hello User,</h1>
                <div className="banner-sub-heading">Welcome to ECO Self Inspection</div>
                <div className="hero-banner-flex">
                    <div className="banner-icon-container">
                        {bannerComponents.map((component,index) => (
                            <div className="icon-container" key={index}>
                                <img
                                    src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images//eco/dashboard/${component.toLowerCase()}.png`}
                                    alt="ECO Inspection"
                                    className="banner-icon"
                                />
                                <div className="text-icon">{component}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="img-width">
                <img
                    src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images//eco/dashboard/banner-img.png`}
                    alt="ECO Inspection"
                    className="banner-img2"
                />
            </div>
        </div>    
    </div>
  )
}

export default Banner
