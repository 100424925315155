import { Card, Container } from 'react-bootstrap';
import OrderBody from './OrderBody';

const OrderCompletedCard = ({ orderNumber,make, model, year, trim, rcNumber, customerName, customerAddress, inspectionStart, inspectionCompleted}) => {
    return (
        <div>
            <Container>
                <Card className="text-center bg-white rounded-sm border-0 shadow-sm">
                    <OrderBody
                        make={make}
                        model={model}
                        year={year}
                        trim={trim}
                        rcNumber={rcNumber}
                        orderNumber={orderNumber}
                        customerName={customerName}
                        customerAddress={customerAddress}
                        inspectionStart={inspectionStart}
                        inspectionCompleted={inspectionCompleted}
                    />
                    <div className="p-3">Completed</div>
                </Card>
            </Container>
        </div>
    );
};

export default OrderCompletedCard;
