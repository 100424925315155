import axios from 'axios';
import { useState } from 'react';
import '@resources/css/tutorial.css';
import { useHistory, useParams } from 'react-router-dom';
import CheckBox from '@pages/SelfInspection/Common/CheckBox';
import Guidelines from '@pages/SelfInspection/Common/Guidelines';
import AlertMessage from '@pages/SelfInspection/Common/AlertMessage';
import SubmitButton from '@pages/SelfInspection/Common/SubmitButton';
import DemoServices from '@pages/SelfInspection/Tutorials/DemoServices';
import VideoTutorial from '@pages/SelfInspection/Tutorials/VideoTutorial';

const Tutorials = () => {
    const params = useParams();
    const history = useHistory();
    const [message, setmessage] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const guidelines = [
        "Go through the guideline video for better results and ease.",
        "Use 'Google Translate' to choose your page language.",
    ];
    const submitHandler = () => {
        if (!isChecked) {
            setmessage('Click on I watched the video.');
            return;
        }
        axios.post(`/orders/${params.orderId}/inspection-ai/guidelines`)
            .then(() => {
                history.push('perform-inspection');
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onClose = () => {
        setmessage('');
    };

    return (
        <div>
            <DemoServices />
            <h3 className="text-center mt-4 text-underline">Guidelines</h3>
            <Guidelines guidelines={guidelines} />
            <VideoTutorial url="https://www.youtube.com/embed/2hpLypSwFW8?si=oiRxbmjSX4mjZAjI" />
            <div className="m-4 d-flex justify-content-center">
                <CheckBox isChecked={isChecked} 
                          onChange={handleCheckboxChange} 
                          message='I watched the video.' 
                />
            </div>
            <div className="home p-2">    
                <AlertMessage message={message} onClose={onClose} />
            </div>
            <div className='text-center'>
                <SubmitButton
                    className="px-3 py-3 text-white fs-6"
                    disabled={!isChecked}
                    onClick={submitHandler}
                >
                    Proceed For Inspection
                </SubmitButton>
            </div>
        </div>
    );
};

export default Tutorials;
