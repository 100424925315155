import axios from 'axios';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useOTPVerification = () => {
    const history = useHistory();
    const params = useParams();

    const [maskedNumber, setMaskedNumber] = useState('');
    const [otpPopUp, setOtpPopUp] = useState(false);

    const sendOTP = async () => {
        const { data } = await axios.post(`/orders/${params.orderId}/otp/send`);
        if (data.StatusCode == 1) {
            setMaskedNumber(data.Result.masked_phone);
        }
    };

    const verifyOTP = async otp => {
        const { data } = await axios.post(`/orders/${params.orderId}/otp/verify`, {
            otp
        });
        if (data.StatusCode == 1) {
            history.push('start');
        }
    };

    const closeModal = () => {
        setOtpPopUp(false);
        setMaskedNumber('');
    };

    useEffect(() => {
        if (maskedNumber) {
            setOtpPopUp(true);
        }
    }, [maskedNumber]);

    return {
        maskedNumber,
        otpPopUp,
        sendOTP,
        verifyOTP,
        closeModal,
    };
};

export default useOTPVerification;
