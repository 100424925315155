import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    components: []
};

export const reInspectionComponentsSlice = createSlice({
    name: 'ReInspectionComponentsSlice',
    initialState,
    reducers: {
        initializeReInspectionComponents: (state, action) => {
            state.components = action.payload || [];
        },
        updateReInspectionComponentFields: (state, action) => {
            const { name, value } = action.payload;
            if (state.components[name]) {
                state.components[name] = value;
            } else {
                state.components = { ...state.components, [name]: value };
            }
        },
        resetSlice: () => initialState
    }
});

export const { updateReInspectionComponentFields, initializeReInspectionComponents, resetSlice } =
    reInspectionComponentsSlice.actions;
