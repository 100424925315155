import axios from 'axios';
import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import BucketGuidelines from './BucketGuidelines';
import SubHeader from '@pages/SelfInspection/Common/SubHeader';
import Loader from '@pages/SelfInspection/Common/Loader';

const AIBuckets = () => {
    const [loading, setLoading] = useState(true);
    const [bucketList, setBucketList] = useState([]);
    const [reInspectionBucketList, setReInspectionBucketList] = useState([]);
    const [checkGuideline, setCheckGuideline] = useState(true);
    const [selectBucketOnClick, setSelectBucketOnClick] = useState(null);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json-ai/buckets`).then(res => {
            if (res.data.StatusCode == 1) {
                setCheckGuideline(!res.data.Result.some(item => item.remaining == 1));
                setBucketList(res.data.Result);
                setLoading(false);
            }
        });
    }, [params.orderId]);

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json-ai/buckets/reinspection`).then(res => {
            if (res.data.StatusCode == 1) {
                setReInspectionBucketList(res.data.Result);
                setLoading(false);
            }
        });
    }, [params.orderId]);

    if (loading) {
        return <Loader />;
    }

    const GetFilledStatus = ({ bucket, reInspectionBucketList }) => {
        if (!bucket || !reInspectionBucketList) {
            return null;
        }
        const isFull = Object.values(reInspectionBucketList[bucket?.name] || {}).every(value => value == false);

        const getStatusIcon = () => {
            if (bucket.remaining > 0 || !isFull) {
                return `${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/orange1.svg`;
            }
            if (bucket.remaining === 0 && isFull) {
                return `${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/tick.svg`;
            }

            return null;
        };

        const iconSrc = getStatusIcon();
        return iconSrc && <img src={iconSrc} className="basket-status-icon" alt="Basket Status Icon" />;
    };

    const allCompleted = bucketList.every(item => item.remaining === 0);

    const reinspectionBucketCompleted = Object.values(reInspectionBucketList).every(item =>
        Object.values(item).every(value => value === false)
    );

    const handleFinishClick = () => {
        axios.post(`/orders/${params.orderId}/inspection-ai/complete`).then(() => history.push('../completed'));
    };

    const handleCardClick = bucket => {
        if (bucket.remaining === 0 || bucket.bucketGuidelines.length === 0) {
            history.push(`ai/buckets/${bucket.id}`);
        } else {
            setSelectBucketOnClick(bucket);
        }
    };

    return (
        <>
            <SubHeader showPageCount={false} />
            <div className="p-3">
                {!selectBucketOnClick ? (
                    <>
                        {bucketList?.map(bucket => (
                            <Card
                                key={bucket.id}
                                onClick={() => handleCardClick(bucket)}
                                className="my-2 rounded shadow-sm p-3 basket d-flex align-items-center justify-content-between gap-3 flex-row"
                            >
                                <div className="basket-content fs-5">{bucket.displayName}</div>
                                <div className="icon-el">
                                    <GetFilledStatus bucket={bucket} reInspectionBucketList={reInspectionBucketList} />
                                </div>
                            </Card>
                        ))}

                        {allCompleted && reinspectionBucketCompleted && (
                            <div className="text-center mt-3">
                                <button className="btn btn-success w-100" onClick={handleFinishClick}>
                                    Finish
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <BucketGuidelines guidelines={selectBucketOnClick.bucketGuidelines} bucket={selectBucketOnClick} />
                )}
            </div>
        </>
    );
};

export default AIBuckets;
