import TickSign from './TickSign';
import TakeVideo from './TakeVideo';
import { Form } from 'react-bootstrap';
import VideoUpload from './VideoUpload';
import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetVideos, updateVideos } from '@redux/AIRedux/aiBasedSlice';
import { updateReInspectionComponentFields } from '@redux/AIRedux/reInspectionSlice';

const CommonVideo = ({ componentOptions, componentDetails }) => {
    const { is_required = 0, upload_source } = componentOptions;
    const [errorMessage, setErrorMessage] = useState('');
    const [validVideo, setValidVideo] = useState(true);

    const { componentName, componentCode, reInspectionComponents = [] } = componentDetails;
    const { videos } = useSelector(state => state.aiServiceData);
    const { videos: videosSlice } = useSelector(state => state.aiBased);

    const selectedFiles = videosSlice?.[componentCode] || videos?.[componentCode] || [];
    const requiredFile = parseInt(is_required) && !selectedFiles.length ? 1 : 0;
    const isValidVideo = !validVideo ? 1 : requiredFile;
    const isRequired = reInspectionComponents[componentCode] == true ? 1 : isValidVideo;

    const dispatch = useDispatch();
    const handleVideoChange = e => {
        dispatch(resetVideos({ name: componentCode }));

        Array.from(e.target.files).forEach(file => {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';
            videoElement.src = URL.createObjectURL(file);

            videoElement.onloadedmetadata = () => {
                const duration = videoElement.duration;
                if (duration <= 90) {
                    if (file?.size > 500 * 1024 * 1024) {
                        setErrorMessage('Please ensure the video size is below 500MB.');
                        setValidVideo(false);
                        return;
                    }
                    setErrorMessage('');
                    setValidVideo(true);
                    dispatch(
                        updateVideos({
                            name: componentCode,
                            video: file
                        })
                    );

                    dispatch(
                        updateReInspectionComponentFields({
                            name: componentCode,
                            value: false
                        })
                    );
                    return;
                }
                setErrorMessage('Please ensure your video fits within 1 minute time frame.');
                setValidVideo(false);
            };
        });
    };
    return (
        <div>
            <Fragment>
                <Form.Label>{componentName}</Form.Label>
                {upload_source == 'c' ? (
                    <div className="d-flex gap-1 ">
                        <div className="m-auto">
                            <TakeVideo handleVideoChange={handleVideoChange} required={isRequired} />
                        </div>
                        {!isRequired && <TickSign />}
                    </div>
                ) : (
                    <VideoUpload handleVideoChange={handleVideoChange} />
                )}
                {errorMessage && <div className="text-danger mt-2 text-sm">{errorMessage}</div>}
            </Fragment>
        </div>
    );
};
export default CommonVideo;
