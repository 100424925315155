import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    make: null,
    model: null,
    year: null,
    trim: null,
    rcNumber: null,
    technicianId: null,
    orderStatus: null,
    orderNumber: null,
    inspectionStart: null,
    customerName: null,
    customerAddress: null,
    aiBased: false,
    inspectionCompleted: null,
};
export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrderDetail: (state, action) => {
            const {
                make,
                model,
                year,
                trim,
                rcNumber,
                orderNumber,
                customerName,
                customerAddress,
                orderStatus,
                technicianId,
                obvCategoryId,
                aiBased,
                inspectionStart,
                inspectionCompleted
            } = action.payload;
            state.make = make,
            state.model = model,
            state.year = year,
            state.trim = trim,
            state.rcNumber = rcNumber,
            state.obvCategoryId = obvCategoryId;
            state.technicianId = technicianId;
            state.orderStatus = orderStatus;
            state.orderNumber = orderNumber;
            state.customerName = customerName;
            state.customerAddress = customerAddress;
            state.aiBased = aiBased;
            state.inspectionStart = inspectionStart;
            state.inspectionCompleted = inspectionCompleted;
        }
    }
});
export const { setOrderDetail } = orderSlice.actions;
