import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubHeader from '../Common/SubHeader';
import OrderDetailsCard from './OrderDetailsCard';
import RequestModal from '../Modals/RequestModal';
import { ACCEPTED, INSPECTION_STARTED, COMPLETED } from './Statuses';
const OrderDetails = () => {
    const history = useHistory();
    const order = useSelector(state => state.order);

    const [model, setModel] = React.useState(false);

    React.useEffect(() => {
        if (order.orderStatus === ACCEPTED) {
            return history.push('start');
        }

        if (order.orderStatus === INSPECTION_STARTED) {
            return history.push('start');
        }

        if (order.orderStatus === COMPLETED) {
            return history.push('completed');
        }
    }, [order.orderStatus]);

    const submitHandler = () => {
        axios.post(`/orders/${order.orderNumber}/inspection/accept`).then(res => {
            if (res.data.StatusCode == 1) {
                history.push('start');
            }
        });
    };

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="bg-body-gray">
                    {model ? (
                        <RequestModal onAccept={submitHandler} />
                    ) : (
                        <OrderDetailsCard
                            orderNumber={order.orderNumber}
                            timeSlot={order.timeSlot}
                            customerName={order.customerName}
                            customerAddress={order.customerAddress}
                            onAccept={() => setModel(true)}
                        />
                    )}
                </div>
            </Container>
        </>
    );
};

export default OrderDetails;
