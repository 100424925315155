import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubHeader from '../Common/SubHeader';
import OrderCompletedCard from './OrderCompletedCard';
import { COMPLETED } from './Statuses';
const OrderCompleted = () => {
    const history = useHistory();
    const order = useSelector(state => state.order);

    React.useEffect(() => {
        if (order.orderStatus !== COMPLETED) {
            history.push('start');
        } else {
            history.push('dashboard');
        }
    }, [order.orderStatus]);

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="bg-body-gray">
                    <OrderCompletedCard
                        make={order.make}
                        model={order.model}
                        year={order.year}
                        trim={order.trim}
                        rcNumber={order.rcNumber}
                        orderNumber={order.orderNumber}
                        customerName={order.customerName}
                        customerAddress={order.customerAddress}
                        inspectionStart={order.inspectionStart}
                        inspectionCompleted={order.inspectionCompleted}
                    />
                </div>
            </Container>
        </>
    );
};

export default OrderCompleted;
