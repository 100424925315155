import { useState, useEffect, useRef } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateComponentFields } from '@redux/AIRedux/aiBasedSlice';
import { updateReInspectionComponentFields } from '@redux/AIRedux/reInspectionSlice';

const TextInput = ({ componentOptions, componentDetails }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [validationError, setValidationError] = useState('');
    const { is_required = 0 } = componentOptions;
    const { componentName = null, componentCode = null, reInspectionComponents = [] } = componentDetails;

    const { data } = useSelector(state => state.aiServiceData);

    const requiredData = data[componentCode] ? 0 : is_required;
    const [isRequired, setIsRequired] = useState(reInspectionComponents[componentCode] == true ? 1 : requiredData);
    const [value, setValue] = useState(data[componentCode] || '');

    useEffect(() => {
        setValue(isRequired ? '' : value && value != data[componentCode] ? value : data[componentCode] || '');
    }, [reInspectionComponents, componentCode]);

    const handleChange = e => {
        setValue(e.target.value);
        setValidationError(ref.current.validationMessage);

        dispatch(
            updateComponentFields({
                name: componentCode,
                value: e.target.value
            })
        );
        setIsRequired(false);
        if (reInspectionComponents[componentCode]) {
            dispatch(
                updateReInspectionComponentFields({
                    name: componentCode,
                    value: false
                })
            );
        }
    };

    useEffect(() => {
        setValidationError(ref.current.validationMessage);
    }, [ref]);

    return (
        <Form.Group>
            <Form.Label>{componentName}</Form.Label>
            <FormControl ref={ref} type="text" value={value} onChange={handleChange} required={isRequired} />
            <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default TextInput;
