import { useState, useEffect, Fragment } from 'react';
import Component from '@pages/SelfInspection/AI/Components/Component';
const Parameter = ({ components: allComponents }) => {
    const [componentList, setComponentList] = useState(allComponents);
    useEffect(() => {
        setComponentList(allComponents);
    }, [allComponents]);
    return (
        <div>
            {componentList?.map((component, index) => {
                return (
                    <Fragment key={index}>
                        <Component
                            index={index}
                            component={component}
                            setComponentList={setComponentList}
                            components={componentList}
                            allComponents={allComponents}
                        />
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Parameter;
