import React from 'react';
import Home from './Home';
import '../css/cerapro.css';
import '../css/Dashboard.css';
import '../css/Banner.css';
import Banner from '../AI/Dashboard/Banner';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Banner />
            <Home />
        </div>
    );
};

export default Dashboard;
