import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OTPModal from '@pages/SelfInspection/Modals/OTPModal';
import useOTPVerification from '../AI/CustomHooks/useOTPVerification';

const ButtonSlider = () => {
    const history = useHistory();
    const { orderNumber } = useSelector(state => state.order);
    const [sliderPosition, setSliderPosition] = useState(10);
    const [isDragging, setIsDragging] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const sliderRef = useRef(null);

    const { maskedNumber, otpPopUp, sendOTP, verifyOTP, closeModal } = useOTPVerification();

    const handlePointerDown = e => {
        if (isCompleted) return;
        setIsDragging(true);

        // Prevent scrolling on mobile during drag
        document.body.style.touchAction = 'none';
    };

    const handlePointerMove = e => {
        if (!isDragging || !sliderRef.current || isCompleted) return;

        const slider = sliderRef.current;
        const sliderRect = slider.getBoundingClientRect();

        // Determine pointer position (touch or mouse)
        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const offsetX = clientX - sliderRect.left;

        const sliderWidth = sliderRect.width;
        const newPosition = Math.min(Math.max((offsetX / sliderWidth) * 100, 10), 90);

        setSliderPosition(newPosition);

        if (newPosition >= 90) {
            finalizeSlider();
        }
    };

    const handlePointerUp = () => {
        if (!isDragging) return;

        setIsDragging(false);

        // Reset touch action to allow scrolling
        document.body.style.touchAction = 'auto';

        if (sliderPosition < 90 && !isCompleted) {
            setSliderPosition(10); // Reset to start if not completed
        }
    };

    const finalizeSlider = async () => {
        setIsDragging(false);
        setIsCompleted(true);

        // Smoothly animate to 100% position
        setSliderPosition(90);

        if (orderNumber) {
            history.push('start');
        } else {
            await sendOTP();
        }
    };

    return (
        <>
            {otpPopUp ? (
                <OTPModal number={maskedNumber} handleClose={closeModal} handleVerify={verifyOTP} submitHandler={finalizeSlider}/>
            ) : (
                <div className="outer">
                    <div
                        className={`button ${isCompleted ? 'complete' : ''}`}
                        ref={sliderRef}
                        onTouchMove={handlePointerMove}
                        onMouseMove={handlePointerMove}
                        onTouchEnd={handlePointerUp}
                        onMouseUp={handlePointerUp}
                    >
                        <div
                            className="slider-handle"
                            style={{
                                left: `${sliderPosition}%`,
                                transition: isDragging || isCompleted ? 'none' : 'left 0.3s ease',
                            }}
                            onTouchStart={handlePointerDown}
                            onMouseDown={handlePointerDown}
                        >
                            <span className="right-arrow">›</span>
                        </div>
                        <div className={`text ${isCompleted ? 'active' : ''}`}>
                            {isCompleted ? 'Start Inspection' : 'Slide to Proceed'}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ButtonSlider;
