import DropDown from '../Elements/DropDown';
import { useSelector } from 'react-redux';
import InputText from '../Elements/InputText';
import CommonVideo from '../Elements/CommonVideo';
import PhotoUpload from '../Elements/PhotoUpload';
import SampleVideo from '../Elements/SampleVideo';
import SignaturePad from '../Elements/SignaturePad';
import ImageView from '@pages/SelfInspection/Elements/ImageView';
const uiMapper = {
    tb: InputText,
    dd: DropDown,
    sg: SignaturePad,
    ui: PhotoUpload,
    vr: CommonVideo,
    iv: ImageView,
    vv: SampleVideo
};
const Component = ({ index, component, setComponentList, components, allComponents }) => {
    const Element = uiMapper[component?.element_type];
    const { components: reinspectionComponents } = useSelector(state => state.reInspectionData);
    const { hiddenComponents } = useSelector(state => state.aiBased);
    const isHidden = hiddenComponents.includes(component?.code);

    if (isHidden) {
        return null;
    }
    return (
        <div>
            <Element
                componentOptions={component?.component_options}
                componentDetails={{
                    components,
                    setComponentList,
                    allComponents,
                    componentIndex: index,
                    componentId: component?.id,
                    componentCode: component?.code,
                    componentName: component?.name,
                    type: component?.element_type,
                    reInspectionComponents: reinspectionComponents
                }}
            />
        </div>
    );
};

export default Component;
