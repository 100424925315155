const Footer = () => {
    return (
      <div className="footerdb sticky-footer">
          <div>
              <img
                  src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/eco-logo.png`}
                  alt="eco"
                  className="eco-img"
              />
          </div>
          <div>
              <img
                  src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/powerby-droom.png`}
                  alt="droom"
                  className="droom-img"
              />
          </div>
      </div>
    ) 
  }
  
  export default Footer
  