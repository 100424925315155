import Banner from './Banner';
import '@resources/css/tutorial.css';
import { Card } from 'react-bootstrap';
import ButtonSlider from '@pages/SelfInspection/Common/ButtonSlider';

const PerformInspection = () => {
    const generalInfo = [
        'Turn on your location',
        'Keep your car neat and clean',
        'Keep your documents ready for the inspection'
    ];

    return (
        <>
            <Banner />
            <div className="performance my-4">
                <Card className='dashboard'>
                    <Card.Img variant="top" src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/inspection.png`} />
                    <Card.Body>
                        <Card.Title className="text-center fs-2">Perform Inspection</Card.Title>
                        <ul className="perform-list">
                            {generalInfo.map((info, index) => (
                                <li key={index}>{info}</li>
                            ))}
                        </ul>
                    </Card.Body>
                </Card>
                <div>
                    <ButtonSlider />
                </div>
            </div>
        </>
    );
};

export default PerformInspection;
