import React from 'react';
import { Card } from 'react-bootstrap';
const OrderBody = ({ orderNumber, make, model, year, trim, rcNumber, customerName, customerAddress, inspectionStart, inspectionCompleted }) => {
    return (
        <Card className="border-0">
            <Card.Title className="py-4 card-header-bg-blue text-center">
                <Card.Img
                    variant="top"
                    src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/order.svg`}
                    className="order-icon me-3"
                />
                Order - {orderNumber}
            </Card.Title>
            <Card.Body className="text-start">
                <Card.Title className="d-flex justify-content-between">
                    Inspection Vehicle Details
                </Card.Title>
                <ul className="customer-details">
                    <li className="d-flex justify-content-between">
                        <Card.Text>Make</Card.Text>
                        <Card.Text className='text-end'>{make}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Model</Card.Text>
                        <Card.Text className='text-end'>{model}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Year</Card.Text>
                        <Card.Text className='text-end'>{year}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Trim</Card.Text>
                        <Card.Text className='text-end'>{trim}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Registration Number</Card.Text>
                        <Card.Text className='text-end'>{rcNumber}</Card.Text>
                    </li>
                </ul>
                <Card.Title className="d-flex justify-content-between">
                    Inspection Order Details
                </Card.Title>
                <ul className="customer-details">
                    <li className="d-flex justify-content-between">
                        <Card.Text>Customer Name</Card.Text>
                        <Card.Text className='text-end'>{customerName}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Customer Address</Card.Text>
                        <Card.Text className='text-end'>{customerAddress}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Inspection Start Time</Card.Text>
                        <Card.Text className='text-end'>{inspectionStart}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Inspection Completed Time</Card.Text>
                        <Card.Text className='text-end'>{inspectionCompleted}</Card.Text>
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
};

export default OrderBody;