import { useState } from 'react';
import '@resources/css/tutorial.css';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SubmitButton from '@pages/SelfInspection/Common/SubmitButton';
import AlertMessage from '@pages/SelfInspection/Common/AlertMessage';
import Guidelines from '@pages/SelfInspection/Common/Guidelines';

const BucketGuidelines = ({ guidelines = [], bucket }) => {
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleSubmit = () => {
      if (!isChecked) {
        setMessage("Note the Instructions and select 'I Understand' below.");
        return;
      }
      history.push(`ai/buckets/${bucket.id}`);
  };

  const onClose = () => {
    setMessage('');
  };

  return (
    <div>
      <h3 className="text-center mt-4 text-underline">Guidelines</h3>
      <AlertMessage message={message} onClose={onClose} />
      <p>Read the Instruction below, understand & proceed further.</p>
      {guidelines && guidelines.length > 0 && (
        <Guidelines guidelines={guidelines} />
      )}

      <Form.Group>
        <Form.Check 
          type="checkbox" 
          label="I understand the instructions." 
          checked={isChecked} 
          onChange={handleCheckboxChange} 
          id="custom-checkbox" 
        />
      </Form.Group>

      <SubmitButton className="mt-3 w-100"  variant={isChecked ? 'primary' : 'secondary'} disabled={!isChecked} onClick={handleSubmit}>
        Proceed
      </SubmitButton>
    </div>
  );
};

export default BucketGuidelines;