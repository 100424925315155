import { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container } from 'react-bootstrap';
import Screen from '../Screens/Screen';
import Loader from '../Common/Loader';
import SubHeader from '../Common/SubHeader';
import { updateScreenField, updateBucketField } from '@redux/FormRedux/reducers/formSlice';

const VehicleDetailsForm = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);

    const { form, files } = useSelector(state => state.form);
    const { aiBased } = useSelector(state => state.order);

    const [buttonState, setButtonState] = useState({
        name: 'Loading...',
        disabled: true
    });
    const [loading, setLoading] = useState(true);
    const [screenList, setScreenList] = useState([]);
    const [currScreenData, setCurrScreenData] = useState({});
    const [currScreenIndex, setCurrentScreenIndex] = useState(0);

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json/buckets/${params.bucketId}`).then(res => {
            if (res.data.StatusCode == 1) {
                dispatch(updateBucketField({ bucketName: res.data.Result.name, bucketId: res.data.Result.id }));
            }
        });

        axios.get(`/orders/${params.orderId}/json/buckets/${params.bucketId}/screens`).then(res => {
            if (res.data.StatusCode == 1) {
                const notFilledIndex = res.data.Result.findIndex(screen => !screen.filled);
                if (notFilledIndex === -1) {
                    return history.push('../start');
                }
                setCurrentScreenIndex(notFilledIndex);
                setScreenList(res.data.Result);
            }
        });
    }, [params.orderId, dispatch, params.bucketId]);

    useEffect(() => {
        if (aiBased) {
            history.push(`../ai/buckets/${params.bucketId}`);
        }
    }, [params.orderId]);

    useEffect(() => {
        if (!screenList.length) {
            return;
        }

        axios
            .get(`/orders/${params.orderId}/json/buckets/${params.bucketId}/screens/${screenList[currScreenIndex]?.id}`)
            .then(res => {
                if (res.data.StatusCode == 1) {
                    setCurrScreenData(res.data.Result);
                    setButtonState({
                        name: 'Next',
                        disabled: false
                    });
                    window.scrollTo(0, 0);
                }
            });
    }, [params.orderId, params.bucketId, currScreenIndex, screenList]);

    useEffect(() => {
        if (currScreenData) {
            dispatch(updateScreenField({ screenId: currScreenData.id, screenName: currScreenData.screen_name }));
            setLoading(false);
        }
    }, [currScreenData, dispatch]);

    const checkFormValidity = formElement => {
        if (!formElement) {
            return false;
        }

        const valid = formElement.checkValidity();
        if (valid) {
            setValidated(false);
            return true;
        }

        setValidated(true);
        const invalidInput = formElement.querySelector('input:invalid,select:invalid');
        if (invalidInput) {
            invalidInput.focus();
        }
        return false;
    };

    const handleNextClick = event => {
        event.preventDefault();
        if (!checkFormValidity(event.target.closest('form'))) {
            return;
        }

        setButtonState({
            name: 'Saving...',
            disabled: true
        });

        let formData = new FormData();
        if (form?.[0]?.['screens']?.[0]?.['parameters']?.length) {
            formData.append('buckets', JSON.stringify(form));
        }
        Object.entries(files).forEach(([name, files]) => {
            formData.append(name, files[0]);
        });

        axios
            .post(`/orders/${params.orderId}/inspection/${params.bucketId}/${currScreenData.id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => {
                if (res.data) {
                    if (currScreenIndex + 1 < screenList.length) {
                        return setCurrentScreenIndex(curr => curr + 1);
                    }

                    return history.push('../start');
                }
            });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Container>
            <SubHeader
                screenNo={currScreenIndex + 1}
                screenName={currScreenData.screen_name}
                screenCount={screenList.length}
            />
            <Form noValidate validated={validated} className="d-flex flex-column gap-4">
                <Fragment>
                    <Screen
                        screenId={currScreenData.id}
                        parameters={currScreenData.parameters}
                        bucketId={params.bucketId}
                    />
                </Fragment>
                <Button disabled={buttonState.disabled} onClick={handleNextClick}>
                    {buttonState.name}
                </Button>
            </Form>
        </Container>
    );
};

export default VehicleDetailsForm;
