import React from 'react';
import Header from './Header';
import Footer from '@pages/SelfInspection/AI/Layout/Footer';
import { BrowserView, MobileView } from 'react-device-detect';

const Layout = ({ hasHeader = true, hasFooter = true, menu, isHidenMenu, children }) => {
    return (
        <>
            <MobileView>
                {hasHeader && <Header menu={menu} isHidenMenu={isHidenMenu}/>}
                <div className='content-1'>
                    {children}
                </div>
                <div className="mt-2">
                    {hasFooter && <Footer />}
                </div>
            </MobileView>
            <BrowserView>
                <h1>Please open this url in mobile device to proceed with inspection.</h1>
            </BrowserView>
        </>
    );
};

export default Layout;
