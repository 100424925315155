import Alert from 'react-bootstrap/Alert';

const AlertMessage = ({message,onClose,variant ='danger'}) => {
  return (
    <>
      {message && (
        <Alert
          variant={variant}
          className="mb-3 d-flex justify-content-between align-items-center"
        >
          <span>{message}</span>
          <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
          ></button>
        </Alert>
      )}
    </>
  )
}

export default AlertMessage