import React from 'react'

const CheckBox = ({isChecked,onChange,message}) => {
  return (
    <div>
      <label className="d-flex align-items-center">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                className="checkbox-space"
            />
            {message}
        </label>
    </div>
  )
}

export default CheckBox
