import { ListGroup } from 'react-bootstrap';
const Guidelines = ({guidelines}) => {
  return (
    <>
        <ListGroup as="ol" numbered bsPrefix="list-items" className="m-4">
            {guidelines.map((guideline, index) => (
                <ListGroup.Item as="li" key={index}>{guideline}</ListGroup.Item>
            ))}
        </ListGroup>
    </>
  )
}

export default Guidelines
