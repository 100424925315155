import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateComponentFields, updateHiddenComponents } from '@redux/AIRedux/aiBasedSlice';

const Dropdown = ({ componentDetails, componentOptions }) => {
    const { is_required = 0, is_multiple = 0, options = [] } = componentOptions;
    const { dependentField } = useSelector(state => state.form);
    const { data } = useSelector(state => state.aiServiceData);
    const dispatch = useDispatch();

    const {
        componentName = null,
        componentCode = null,
        setComponentList,
        reInspectionComponents = [],
        allComponents = []
    } = componentDetails;
    const isRequired = reInspectionComponents[componentCode] ? 1 : data[componentCode] ? 0 : parseInt(is_required);

    const [selected, setSelected] = useState(data[componentCode] || '');
    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        setSelected(isRequired ? '' : data[componentCode] || '');
    }, [isRequired, componentCode]);

    const submitHandler = e => {
        e.preventDefault();

        setSelected(e.target.value);
        if (isRequired && !e.target.value) {
            setValidationError(`Please select a value for ${componentName.replace(/_/g, ' ')}.`);
        } else {
            setValidationError('');
        }

        dispatch(
            updateComponentFields({
                name: componentCode,
                value: e.target.value
            })
        );
    };

    useEffect(() => {
        if (!selected) return;
        if (componentOptions.events) {
            const hideComps = [];
            const events = componentOptions.events.filter(event => event.option_id === selected);
            events.forEach(event => {
                if (event.impact_level !== 'component') return;

                event.action.forEach(act => {
                    if (act.impact_type == 'hide') {
                        hideComps.push(act.component_code);
                    }
                });
            });
            setComponentList(
                Array.isArray(allComponents)
                    ? allComponents.filter(comp => !hideComps.some(com => com == comp.code))
                    : []
            );
            dispatch(updateHiddenComponents(hideComps));
        }
    }, [selected, componentOptions]);

    const compOptions = dependentField?.[componentName]?.values || options;

    return (
        <>
            <Form.Label>{componentName}</Form.Label>
            <Form.Control
                as="select"
                aria-label={componentName}
                required={Boolean(isRequired)}
                multiple={Boolean(parseInt(is_multiple))}
                value={selected}
                onChange={submitHandler}
                isInvalid={!!validationError}
            >
                <option value="">{`Select ${componentName.replace(/_/g, ' ')}`}</option>
                {Object.keys(compOptions).map(key => {
                    return (
                        <option key={key} value={compOptions[key]}>
                            {compOptions[key]}
                        </option>
                    );
                })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
        </>
    );
};
export default Dropdown;
